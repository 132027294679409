import React from 'react';
import SkeletonItem from './SkeletonItem';

const Skeleton = () => {
  return (
    <div className='flex mt-8'>
      <div  className="px-2 lg:px-0 grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-x-2 lg:gap-x-4 gap-y-10 justify-items-start content-start place-content-stretch place-self-start overflow-hidden">  
      {[...Array(3)].map((_, index) => (        
          <SkeletonItem key={'skt-' + index}/>        
      ))}
      </div>
    </div>
  );
};

export default Skeleton;

