import MyImageComponent from "../addons/MyImageComponent";

const SkeletonItem = () => {

	return (
		<div
			className="w-fit product-desktop self-start h-max"
		>
			<div
				className="relative"
			>
				<div>
					<MyImageComponent						
						src={""}
						alt={"skeleton"}
						width={614}
						height={614}
						quality={100}
					/>					
				</div>
			</div>
			<div className="product-info px-1 lg:px-0 relative mt-3">
				<div className="mb-1.5 w-34 h-3 bg-gray-lightest"></div>
				<div className="font-helvetica-text font-normal text-sm md:text-base text-gray-light antialiased mb-1.5 w-12 h-3 bg-gray-lightest"></div>
				<div className="font-helvetica-text font-normal text-sm md:text-base text-gray-light antialiased mb-3 w-20 h-3 bg-gray-lightest"></div>
				<div className="mb-2 w-30 h-3 bg-gray-lightest"></div>
				<div className="flex justify-between px-1 pb-2 relative">
					<div className="coloresMayor flex flex-row flex-wrap relative">
						{[...Array(3)].map(function (_, index) {
							return (
								<div key={index} style={{ backgroundColor: "#c1c1c1" }}
									role="button"
									className={`ring-1 ring-gray-light ring-offset-2 w-4.7 h-4.7
												 border border-solid cursor-pointer transition-all duration-300 ease-linear inline-block rounded-full mr-2 mt-2`}>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	)
};

export default SkeletonItem;