/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import Tooltip from "@mui/material/Tooltip";
import { v4 } from "uuid";
import { AppContext } from "../context/AppContext";
import clientConfig from "../../../client-config";
import Price from "../single-product/price";
import Discount from "../single-product/discount";
import { filterDuplicateColor, getFormattedCart } from "../../functions";
import { itemsGoogleAnalytics, event } from "../trakers/google";
import { fbq, itemsMeta } from "../trakers/meta";
import { trackAddToCartEventTikTok, itemsTikTok } from "../trakers/tiktok";
import DrawerCart from "../cart/cart-page/DrawerCart";
import GET_CART from "../../queries/get-cart";
import ADD_TO_CART from "../../mutations/add-to-cart";
import NewProduct from "../addons/IsNew";
import {getImageFromCloudinary} from "../../functions";
import MyImageComponent from "../addons/MyImageComponent";

const ProductQuickAddForBuilder = ({productId, isMobile, hideFilter}) => {
	const [product, setProduct] = useState(null);
	const [cart, setCart] = useContext(AppContext);
	//const [isShow, setIsShow] = useState(false);
	const [isShowSize, setIsShowSize] = useState(false);
	const [isShowText, setIsShowText] = useState(false);
	const [MyChoise, setMyChoise] = useState();
	const [MyColor, setMyColor] = useState();
	const [activeImage, setActiveImage] = useState(product?.image?.guid ? getImageFromCloudinary(product?.image?.guid, ":best", 614, 614) : "");
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeColors, setActiveColors] = useState();
	const [MyTalla, setMyTalla] = useState();
	const [MyTallaByColor, setMyTallaByColor] = useState();
	const [listVariations, setListVariations] = useState();
	const [showViewCart, setShowViewCart] = useState(false);
	const [menuOpenCart, setMenuOpenCart] = useState(false);
	//const touchCountRef = useRef(0);

	//const revDate = new Date() - new Date(product.admissionDate);

	useEffect(() => {
		async function fetchProduct() {
		  if (!productId) return;
		  const response = await fetch(`/api/product/${productId}`);
		  const data = await response.json();
		  setProduct(data);
		}
	
		fetchProduct();
	  }, [productId]);

	// Get Cart Data.
	const [refetch, { loading: loadCart, error: errorCart, data: dataCart }] = useLazyQuery(GET_CART, {
		fetchPolicy: "no-cache",
		onCompleted: () => {
			// Update cart in the localStorage.
			const updatedCart = getFormattedCart(dataCart);
			localStorage.setItem("classic-cart", JSON.stringify(updatedCart));

			// Update cart data in React Context.
			setCart(updatedCart);

			if (addToCartRes && updatedCart) {
				//enviar a google analytics
				let numero = updatedCart?.totalProductsPrice?.replace("$", "");
				let value = parseFloat(numero.replace(".", "").replace(",", ""));
				let itemsG = itemsGoogleAnalytics(updatedCart);
				let itemsM = itemsMeta(updatedCart);
				let itemsT = itemsTikTok(updatedCart);

				event("add_to_cart", {
					"value": value,
					"currency": "COP",
					"items": itemsG
				});

				fbq("AddToCart", {
					value: value,
					currency: "COP",
					contents: itemsM,
					content_type: "product"
				});
				trackAddToCartEventTikTok("AddToCart", {
					value: value,
					currency: "COP",
					contents: itemsT
				});
				// 2. Show View Cart Button
				setShowViewCart(true);
				if (updatedCart.totalProductsCount == 1)
					setMenuOpenCart(true);
			}


		},
		onError: (errorCart) => {
			if (errorCart) {
				//setRequestError(errorCart?.graphQLErrors?.[0]?.message ? errorDeleteCouponOrder.graphQLErrors[0].message : "");
			}
		}
	});

	// Add to Cart Mutation.
	const [addToCart, { data: addToCartRes, loading: addToCartLoading, error: addToCartError }] = useMutation(ADD_TO_CART, {
		onCompleted: () => {
			refetch();
		},
		onError: (addToCartError) => {
			if (addToCartError) {
				if (addToCartError?.graphQLErrors[0]?.message == "Expired token") {
					var cart = localStorage.getItem("classic-cart");
					if (cart) localStorage.setItem("classic-cart", null);
					localStorage.removeItem("woo-session");
				}
			}
		}
	});

	const variations = product?.variations?.nodes ? product?.variations?.nodes : "";
	//const curvaTallas = product?.paCurvatallas?.nodes[0]?.name ? product?.paCurvatallas?.nodes[0]?.name : "";

	var listTallaByColor = [];
	var tmpVariations = [];

	useEffect(() => {
		if (variations?.length) {
			var listTallaAll = [];
			//Continuar aqui ver como desglozar el problema de los colores del sku
			var listColorAll = variations.map(function (variation, index) {
				var nameColor, talla;
				if (variation?.attributes?.nodes[0]?.name == "pa_color") {
					nameColor = variation?.attributes?.nodes[0]?.value;
					talla = variation?.attributes?.nodes[1]?.value;
				}
				else {
					nameColor = variation?.attributes?.nodes[1]?.value;
					talla = variation?.attributes?.nodes[0]?.value;
				}
				var tmp = variation?.sku?.split("-");

				if (tmp) {
					tmpVariations[index] =
					{
						id: variation?.variationId,
						color: nameColor,
						idColor: tmp[0],
						codigoColor: tmp[1],
						talla: talla,
						stockQuantity: variation?.stockQuantity,
					};

					return {
						id: tmp[0],
						color: nameColor,
						codigoColor: tmp[1],
						image: variation?.image?.guid ? getImageFromCloudinary(variation?.image?.guid, ":best", 614, 614) : clientConfig.productImagePlaceholder282
					};
				}

			});

			for (var i = 0; i < tmpVariations.length; i++) {
				listTallaAll[i] = tmpVariations[i].talla;
			}

			let listColor = filterDuplicateColor(listColorAll);
			if (listColor) {
				setActiveColors(listColor);
				handleClick(listColor[0].id, listColor[0].image, listColor[0].color);
			}

			var hash = {};
			listTallaAll = listTallaAll.filter(function (current) {
				var exists = !hash[current];
				hash[current] = true;
				return exists;
			});

			//ordenar si es por numero
			listTallaAll.sort((a, b) => a - b);
			//ordenar si es por letras
			var listaTallasOrdenadas = ["XS-P", "XS", "S", "M", "L", "XL", "2XL"];
			var listaTmp = [];
			listaTallasOrdenadas.map(function (item) {
				if (listTallaAll?.includes(item)) {
					listaTmp.push(item);
				}
			});

			//Poner datos para primera carga	
			setListVariations(tmpVariations);
			if (listaTmp.length > 0)
				setMyTalla(listaTmp);
			else setMyTalla(listTallaAll);
		}

	}, [product]);


	const handleClick = (id, image, color) => {
		setMyChoise(id);
		setActiveIndex(id);
		let actualImg = getImageFromCloudinary(image, ":best", 614, 614);
		setActiveImage(actualImg);
		setMyColor(color);

		listTallaByColor = [];
		var variaciones = tmpVariations.length > 0 ? tmpVariations : listVariations;

		for (var i = 0; i < variaciones?.length; i++) {
			if (variaciones[i].idColor == id && variaciones[i].stockQuantity) {
				listTallaByColor.push(variaciones[i].talla);
			}
		}
		listTallaByColor.sort((a, b) => a - b);
		setMyTallaByColor(listTallaByColor);
	};

	const handleClickTalla = (selected) => {

		var variaciones = tmpVariations.length > 0 ? tmpVariations : listVariations;

		for (var i = 0; i < variaciones.length; i++) {
			if (variaciones[i].talla == selected) {

				if (MyChoise && MyChoise == variaciones[i].idColor) {

					addToCart({
						variables: {
							input: {
								clientMutationId: v4(),
								productId: product.productId,
								variationId: variaciones[i].id
							},
						}
					});
					return;
				}
			}
		}

	};

/*	const handleTouchEnd = () => {
		touchCountRef.current += 1;

		if (touchCountRef.current === 1) {
			setIsShowText(true);
		} else if (touchCountRef.current === 2) {
			redirectToProduct();
			touchCountRef.current = 0;
		}
	};*/

	const redirectToProduct = () => {
		let url = "/producto/" + product?.slug + "/?idColor=" + activeIndex;
		window.location = url;
	};

	const getSrcSet = (image) => {
		const sizes = [200, 300, 400, 419, 468, 501, 614];
		return sizes.map(size => `${getImageFromCloudinary(image, ":best", size, size)} ${size}w`).join(', ');
	};

	if (!product) return <div>Cargando...</div>;

	//desarrollar un hook o funcion que me vaya cambiando el tamaño de las imagenes

	return (
		// @TODO Need to handle Group products differently.
		undefined !== product && "GroupProduct" !== product.__typename && variations?.length ? (
			<div
				className="w-fit product-desktop place-self-start h-max"
			>
				{/*<Link href="/[slug]" as={`/${product?.slug}`} prefetch={false} passHref>*/}
				<div
					className="relative"
					onMouseEnter={() => setIsShowText(true)}
					onMouseLeave={() => setIsShowText(false)}
				>
					<Discount salesPrice={product?.salePrice} regularPrice={product?.regularPrice} />
					<div
						onClick={() => { /*if (isMobile == undefined || isMobile == false)*/ redirectToProduct(); }}
					>
						<MyImageComponent
							key={activeIndex}
							src={activeImage}
							alt={product?.name}
							width={614}
							height={614}
							quality={100}
							srcSet={getSrcSet(product?.image?.guid)}
						/>
					</div>
					<div className={`${isShowText === true ? "visible" : "hidden"} bg-white bg-white/100 flex flex-col bottom-4 inset-x-4 absolute shadow-lg pb-2 px-1.5 rounded-lg items-center justify-center`}>
						{isShowSize === true ?
							<div className="flex flex-wrap items-center justify-center w-full" onMouseLeave={() => setIsShowSize(false)}>
								{MyTalla?.map(function (item, index) {
									if (MyTallaByColor?.includes(item)) {
										return <span
											key={"talla" + index}
											role="button"
											onClick={() => handleClickTalla(item)}
											className="mt-2 w-9 p-2 hover:bg-gray-light2 rounded-md text-center font-helvetica-text font-normal text-xs antialiased cursor-pointer inline-block transition-all duration-300 ease-linear">
											{item}
										</span>;
									}
									else {
										return <span
											key={"talla" + index}
											role="button"
											className="relative mt-2 w-9 rounded-md text-center font-helvetica-text font-normal text-xs antialiased cursor-not-allowed select-none inline-block transition-all duration-300 ease-linear custom-line-through">
											{item}
										</span>;
									}
								})
								}
							</div>
							: <div
								className="p-2 text-sm font-helvetica-text font-medium antialiased flex flex-row items-center justify-center w-full h-10 pb-0 relative"
								onMouseEnter={() => setIsShowSize(true)}
								onTouchEnd={() => setIsShowSize(true)}
							>Agregar al Carrito</div>
						}
					</div>
				</div>
				{/*</Link>*/}
				<div className="product-info px-1 lg:px-0 relative mt-3">
					<NewProduct fechaAdmision={product?.admissionDate} />					
					<h3 className="product-title font-helvetica-text font-medium text-sm md:text-base antialiased text-black">
						<a href={`/producto/${product.slug}/?idColor=${activeIndex}`}>
							{product?.name ? product?.name : ""}
						</a>
					</h3>
					<p className="font-helvetica-text font-normal text-sm md:text-base text-gray-light mb-1.5 antialiased">{product?.paLineaPorPrecioActuals?.nodes[0]?.name}</p>
					<p className="font-helvetica-text font-normal text-sm md:text-base text-gray-light mb-3 antialiased">Item No. {product?.sku}</p>
					<div className="mb-2"><Price salesPrice={product?.salePrice} regularPrice={product?.regularPrice} isDetail={false} /></div>
					<div className="flex justify-between px-1 pb-2 relative">
						{/*<div className="curvaTallas flex mt-4 text-gray-light text-sm font-helvetica-text font-normal antialiased hidden">{curvaTallas ? curvaTallas : ""}</div>*/}
						<div className="coloresMayor flex flex-row flex-wrap relative">
							{activeColors?.map(function (item, index) {
								//if (index < 3)
								return (
									<Tooltip disableInteractive={true} placement="bottom" arrow title={item.color} key={"color_" + item.id}>
										<div key={index} style={{ backgroundColor: item.codigoColor }}
											role="button"
											onClick={() => handleClick(item.id, item.image, item.color)}
											className={`${item.id === activeIndex ? "ring-1 ring-gray-light ring-offset-2 w-4.7 h-4.7"
												: "border-gray-light2 w-5 h-5"} border border-solid cursor-pointer transition-all duration-300 ease-linear inline-block rounded-full mr-2 mt-2`}>
										</div>
									</Tooltip>);
							})}
						</div>
					</div>
				</div>
				{showViewCart ? (
					<DrawerCart open={menuOpenCart} handleClose={setMenuOpenCart} />
				) : ""}
			</div>
		) : (
			""
		)
	);
};

export default ProductQuickAddForBuilder;
