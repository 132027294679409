import React, { useState, useEffect } from 'react';
import SwiperCarousel from "./swiper";
import ProductQuickAdd from '../../product/ProductQuickAdd';
import { rowCreateByDate } from "../../../functions";
import Skeleton from "../../skeleton";


const ProductSlider = ({ categoryId, title, isLanding, isMobile, hideFilter }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchProducts() {
      setLoading(true);
      try {
        const response = await fetch('/api/products-by-category', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            categoryId: categoryId,
            first: 10, // Ajusta este valor según cuántos productos quieras cargar
            after: null, // Puedes manejar la paginación aquí si es necesario
          }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setProducts(rowCreateByDate(data.productCategory.products.edges.map(edge => edge.node), 9));
      } catch (error) {
        console.error("Error al cargar los productos:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchProducts();
  }, [categoryId]);

  if (loading) return <Skeleton/>;
  if (error) return <div>Error al cargar los productos</div>;

  return (
    <SwiperCarousel title={title} isLanding={isLanding}>
      {products.map((product) => (
        <ProductQuickAdd
          key={product.id}
          product={product}
          isMobile={isMobile}
          hideFilter={hideFilter}
        />
      ))}      
    </SwiperCarousel>
  );
};

export default ProductSlider;